import store from "@/core/services/store";
import router from "@/router";
import txt from "./text.json";
const BackService = {
  init() {
    this.urlAPI = "https://api.urbano.jg.app";
    this.user = null;
    this.lang = "fr";
    store.back = this;
    this.history = [];
    this.txt = txt;
  },
  ajax(route, params = null, callBack) {
    const url = route.search('http') === 0 ? route : this.urlAPI + route;
    let the = this;
    let option = {};
    option.headers = {
      "Content-Type":"application/json",
      Authorization: this.getToken()
    }
    if(params != null){
      option.method = "POST",
      option.body = JSON.stringify(params)
    }
    fetch(url,option).then( response => {
      if(response.status === 401){
        the.destroyToken();
        the.destroyUser();
        router.push({path:'/login'})
      }
      response.json().then( data =>{
        the.res = data;
        if(data.status === false){
          this.toast.toast(data.data, {
            title: 'Erreur',
            variant: 'danger',
            solid: true
          });
        }
        if(callBack != null) {
          callBack(the.res);
        }
      })
    }).catch( res => {
      console.log(res);
    });
  },
  getToken(){
    return window.localStorage.getItem("token");
  },
  saveToken(token){
    window.localStorage.setItem("token", token);
  },
  saveUser(token){
    window.localStorage.setItem("user", JSON.stringify(token));
  },
  getUser(){
    return JSON.parse(window.localStorage.getItem("user"));
  },
  destroyToken(){
    window.localStorage.removeItem("token");
  },
  destroyUser(){
    window.localStorage.removeItem("user");
  },
  timestampToDate(timestamp,datetime = true){
    var date = new Date(timestamp*1000);
    var res = date.toLocaleDateString("fr-Fr");
    if(datetime){
      res += ' '+date.toLocaleTimeString("fr-Fr");
    }
    return res;
  },
  now(){
    var n = new Date().getTime();
    n = n/1000;
    return parseInt(n);
  },
  getText(key){
    var txt = this.txt[this.lang][key];
    if(txt === undefined){
      return '';
    }else{
      return txt;
    }
  },
  getParamTitre(array,ext_val){
    var check = array.find(x => x.id === ext_val);
    if(check === undefined){
      return "Aucun";
    }else{
      return check.titre.fr
    }
  }
};

export default BackService;
