import Vue from "vue";
import App from "./App.vue";
import router from "./router";
//import acl from './acl';
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import Back from "@/core/services/back.service";
import MockService from "@/core/mock/mock.service";
import { APP_VERIFY_AUTH } from "@/core/services/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import VueQuillEditor from 'vue-quill-editor'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.use(VueQuillEditor, /* { default global options } */)

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import 'vue-search-select/dist/VueSearchSelect.css'

/*leaflet*/
import { LMap, LTileLayer, LMarker, LPopup } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
Vue.component('l-map', LMap);
Vue.component('l-popup', LPopup);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
import { Icon } from 'leaflet';
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
/*leaflet*/

// API service init
ApiService.init();
Back.init();
// Remove this to disable mock API
MockService.init();
/*if(Back.user === null){
  router.push({ name: "login" });
}*/
router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  store.role = store.getters.currentUser.role == 1 ? 'isAdmin' : 'isUser';
  if(to.meta.rule === 'isAdmin' && store.role != 'isAdmin'){
    console.log(to)
  }
  //console.log(from)
  Promise.all([store.dispatch(APP_VERIFY_AUTH)]).then(next);

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);
  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

new Vue({
  router,
  //acl,
  store,
  i18n,
  vuetify,
  render: h => h(App),
  beforeMount() {
    Back.toast = this.$bvToast;
  }
}).$mount("#app");
